/* From Uiverse.io by gharsh11032000 */ 
// https://uiverse.io/gharsh11032000/sour-vampirebat-66

@switchWidth: 2.6em;
@switchHeight: 1.6em;
@sliderMarginOff: 0.2em;
@sliderSize: @switchHeight - 2 * @sliderMarginOff;
@sliderMovement: @switchWidth - @switchHeight;
@colorActive: rgba(0, 178, 255, 0.8);
@colorInactive: rgba(0, 178, 255,0.2);    

/* The switch - the box around the slider */
.switch {
    font-size: 1em;
    position: relative;
    display: inline-block;
    width: @switchWidth;
    height: @switchHeight;
    vertical-align: middle;

    &.isLoading {
        opacity: 0.5;
        pointer-events: none;
    }

  /* Hide default HTML checkbox */
  input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}
  
  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background: @colorInactive;
    border-radius: 50px;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.320, 1);
  
  &:before {
    position: absolute;
    content: "";
    height: @sliderSize;
    width: @sliderSize;
    left: @sliderMarginOff;
    bottom: @sliderMarginOff;
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 0px 20px rgba(0,0,0,0.4);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.switch input:checked + .slider {
    background: @colorActive;
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px @colorActive;
  }
  
  .switch input:checked + .slider:before {
    transform: translateX(@sliderMovement);
    width: @switchHeight;
    height: @switchHeight;
    bottom: 0;
  }