// General should be set through Bulma
// body {
//   font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
// }

// a {
//   color: #00B7FF;
// }


// Behaviour classes
.link{
  color: #00B7FF;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

// Horizontal Positioning
.center {
  text-align: center;
}

.right {
  text-align: right;
}

// Vertical Positioning
.top {
  vertical-align: top;
}

// Other
.inline-block {
  display: inline-block
}

.hide {
  display: none!important;
}

.right-margin {
  margin-right: 0.5em;
}

// Formatting
.bold {
  font-weight: bold;
}

.zero {
  color: rgba(0,0,0,0.2)!important;
}

.zen-vertical-align-baseline {
  vertical-align: baseline!important;
}

// Printing
@media print {
  .no-print {
    display: none;
  }
}
