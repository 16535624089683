// .anyClass {
//   font-weight: bold;
// }


// Financial Statements styling
.fs-group{
  font-weight: bold;
}

.fs-sum{
  color: #3c8dbc;
  font-weight: bold;
  border-top: 1px solid #3c8dbc !important;
}

.fs-ratio{
  color: #3c8dbc;
}

.fs-details{
  color: #999999;
}